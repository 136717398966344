import React from 'react';
import LayoutBios from '../components/v2/LayoutBios';

export default function Page(props) {
  React.useEffect(() => {
    props.handleMenu('b')
  })
	return (
    <LayoutBios
      data={data}
    />
	)
}

const data = {
  nvisible: {
    title:'nvisible.studio',
    url:'https://nvisible.studio',
    text: [
      'NVISIBLE.STUDIO is the independent research practice of Finnish designer and filmmaker Emilia Tapprest (b. 1992). She is currently based in Maastricht, NL, and is resident at the Jan van Eyck academie during 2020-2021. Coming from the background of industrial design and interactive prototyping at the Aalto University in Helsinki, she completed her second master in film at the Sandberg Institute in Amsterdam in 2019. With an early interest in ubiquitous computing and ambient intelligence as objects of design, her focus has shifted to investigate implications of emerging technologies and new power arrangements in society at large.'
    ]
  },
  sxmbra: {
    title:'s x m b r a',
    url:'https://newlink.page/sxmbra',
    text: [
    'S X M B R A is the artistic pseudonym of Victor Evink (b. 1987), born in Colombia and adopted to the Netherlands. He is based in Utrecht, where he received an MSc in History and Philosophy of Science from the University of Utrecht in 2013. His work combines STS research with artistic practice, focussing on the evolution of knowledge, technology and peripheral subcultures. As a sound-collage artist, violinist and producer-DJ, he has developed a contrastful, emotional signature style of audiovisual storytelling that blurs the boundaries between genres and ethno-cultural tropes, the sacred and the profane, reality and speculative fiction. His music finds existential meaning and consolation in the trash of modern culture, questions the meaning of roots and identity in a fluid world and reflects his personal struggle with autism spectrum disorder.'
    ]
  },
}
import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import theme from './theme';
import './fonts/Calyces-Regular.css';
import './fonts/Calyces-Bold.css';
import './fonts/LaNord-SemiLight.css';
import './fonts/Brutal-Type-Medium.css';
import './fonts/Brutal-Type-Regular.css';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.querySelector('#root'),
);

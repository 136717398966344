import React from 'react';
import LayoutProject from '../components/v2/LayoutProject';

export default function Page(props) {
	React.useEffect(() => {
    props.handleMenu('p')
  })
	return (
		<LayoutProject
			data={data}
		/>
	)
}

const data = {
  title: 'sonzai.zone',
	url: 'https://sonzai.zone',
	img: 'sonzai.jpg',
  text: [
    "SONZAI.ZONE (2019, 23 min) is a speculative fiction film on intimacy and loneliness after the normalization of ambient communication media. An unlikely encounter between Yún and Souvd takes place in a near-future where social interactions are largely based on the mediation of human presence, known as ‘sonzai-kan’. Shifting between XR games, Immersion Arcades and spatial home displays, their insidiously orchestrated relationship escalates into extreme idealization. Meanwhile, Souvd’s ex-girlfriend Ntzumi launches an undercover investigation.",
    "The film work was developed at the temporary film department of Sandberg instituut. It’s based on design anthropological interventions and expert interviews conducted during 2017-2018 in the context of Aalto University in Finland. The work has been shown at various events and exhibitions such as IMPAKT / Speculative Interfaces (2019, Utrecht), DEMO moving image festival (2020, online) and VISIO / Resisting the Trouble – Moving Images in Times of Crisis (2020, Florence).",
  ]
}

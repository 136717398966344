import React from 'react';
import LayoutProject from '../components/v2/LayoutProject';

export default function Page(props) {
	React.useEffect(() => {
    props.handleMenu('p')
  })
	return (
		<LayoutProject
			data={data}
		/>
	)
}

const data = {
  title: 'zhouwei.network',
	url: 'https://zhouwei.network',
	img: 'zhouwei.jpg',
  text: [
    "ZHŌUWÉI.NETWORK is a design fiction project that explores the relationship between embodiment, datafication and power. Through grounded worldbuilding combined with cinema and audio-fiction, we prototype the affective experiences that emerge within three speculative near-future societies, driven by different ideas about what makes a good life: Dolphin Waves, a corporate playbour & wellness hub (New York City, 2037); Dragonfly, a technocratic state focusing on safety and harmony (Netherlands, 2037); and Project Gecko, a network of decentralised liquid democracies (multiple locations, 2037). The research aims to create a solid foundation for the subsequent development of a 35-minute fiction film. For the series of works produced in 2020, we have focused particularly on the way in which societal paradigms, manifested through technology, affect the relationships people have with their bodies and with each other through their bodies.",
  ]
}

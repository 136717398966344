import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import logo from '../compass.svg';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    background: 'radial-gradient(circle, rgba(240,166,136,1) 33%, rgba(207,207,211,1) 76%, rgba(207,207,211,1) 100%)',
	},
	appBar: {
		paddingTop: '20px'
	},
	logo: {
			width: '40px',
			height: '40px',
			'&::selection': {
				backgroundColor: '#FCE24C'
			}
	},
	main: {
		marginTop: '100px',
		minHeight: '70vh'
	},
	sides: {
		width: '16px',
		margin: '20px',
		marginTop: '5px',
		marginBottom: '5px',
		'&::selection': {
      backgroundColor: '#FCE24C'
    }
	},
	footer: {
		padding: theme.spacing(3, 0),
		marginTop: 'auto',
		color: "#fff",
	},
  footerTitle: {
    color: '#fff',
		fontFamily: 'Calyces Bold',
		textDecoration: 'none',
		'&::selection': {
      backgroundColor: '#FCE24C'
    }
	},
	
}));

export default function Layout({children, start, pause, audioState, menu}) {
	const classes = useStyles();
	
	return (
		<>
			<div className={classes.root}>
			<AppBar
				color='transparent'
				elevation={0}
				className={classes.appBar}
			>
				<Toolbar>
						<Grid container justify='center'>
						<img className={classes.logo} src={logo} alt="compass" />
						</Grid>
				</Toolbar>
			</AppBar>

				<Grid container 
				  direction="row"
					justify="space-between"
					alignItems="center"
					className={classes.main}
				>
				<Grid item xs={2} sm={1}>
					<Grid container
						direction="column"
						justify="center"
						alignItems="flex-start"
					>
						<Grid item xs={12}>
							<img className={classes.sides} alt="off" src={audioState ? 'sides/off.svg' : 'sides/off-s.svg'} onClick={() => pause()}
							style={{cursor: 'pointer'}}
							/>
						</Grid>
						<Grid item xs={12}>
							<img className={classes.sides} alt="slash" src='sides/slash-l.svg' />
						</Grid>
						<Grid item xs={12}>
							<img className={classes.sides} alt="on" src={audioState ? 'sides/on-s.svg' : 'sides/on.svg'} onClick={() => start()}
							style={{cursor: 'pointer'}}
							/>
						</Grid>
						<Grid item xs={12}>
							<img className={classes.sides} alt="sound" src='sides/sound.svg' />
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={8} sm={10}>

        {children}

				</Grid>

				<Grid item xs={2} sm={1}>
					<Grid container
						direction="column"
						justify="center"
						alignItems="flex-end"
					>
						<Grid item xs={12}>
						<Link to='/'>
							<img className={classes.sides} alt="projects" src={menu === 'p' ? 'sides/projects-s.svg' : 'sides/projects.svg'} />
							</Link>
						</Grid>
						<Grid item xs={12}>
							<img className={classes.sides} alt="slash" src='sides/slash-r.svg' />
						</Grid>
						<Grid item xs={12}>
						<Link to='/about'>
							<img className={classes.sides} alt="about" src={menu === 'a' ? 'sides/about-s.svg' : 'sides/about.svg'} />
							</Link>
						</Grid>
						<Grid item xs={12}>
							<img className={classes.sides} alt="slash" src='sides/slash-r.svg' />
						</Grid>
						<Grid item xs={12}>
							<Link to='/bios'>
							<img className={classes.sides} alt="bios" src={menu ==='b' ? 'sides/bios-s.svg' : 'sides/bios.svg'} />
							</Link>
						</Grid>
					</Grid>
				</Grid>

				</Grid>

			<footer className={classes.footer}>
			<Grid item xs={12}
				container
				direction="row"
				justify="center"
				alignItems="flex-end"
			>
        <Typography
			variant='h6'
			className={classes.footerTitle}
			component={Link}
			to='/'
      >
        liminal.vision
      </Typography>
      </Grid>
    	</footer>

			</div>
		</>
	)
}

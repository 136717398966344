import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#280b06',
    background: 'transparent',
  },
  title: {
    fontFamily: 'Calyces Regular',
    '&::selection': {
      backgroundColor: '#FCE24C'
    }
  },
  text: {
		fontFamily: 'Brutal Type Regular',
		color: '#280b06',
    textAlign: 'left',
    marginBottom: theme.spacing(1),
    lineHeight: '120%',
    '&::selection': {
      backgroundColor: '#FCE24C'
    }
  },
  button: {
    borderRadius: 10,
    borderWidth: '1px',
    borderColor: '#fff',
    fontFamily: 'LaNord SemiLight',
    transition: '0s all',
    '&:hover': {
      transitionDelay: '0s',
  		borderColor: '#FCE24C',
			color: '#FCE24C',
		}
  }
}));

export default function CenteredGrid(props) {
  const classes = useStyles()
  const { title, text, url } = props

  return (
    <div className={classes.root}>

    <Fade 
    in={true}
    timeout={1000}
    >
      <Grid container>
        <Grid item xs={12}>
        <Paper className={classes.paper} elevation={0}>
          <Typography className={classes.title}
            component='h1'
            variant='h5'
          >
            {title}
          </Typography>
        </Paper>
        </Grid>

        <Grid item xs={12}>
        <Paper className={classes.paper} elevation={0}>
          {text.map((p, i) => 
          <Typography key={i} className={classes.text}
            variant='body2'
            component='p'
          >
            {p}
          </Typography>
          )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0}>
            <Button href={url} className={classes.button}
              variant="outlined"
              size="small"
            >
              WEBSITE
            </Button>
          </Paper>
        </Grid>
      </Grid>
      </Fade>
    </div>
  );
}
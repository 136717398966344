import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import IconButton from '@material-ui/core/IconButton';
import Mail from '@material-ui/icons/Mail';
import Instagram from '@material-ui/icons/Instagram';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: '#280b06',
    background: 'transparent',
  },
	text: {
		fontFamily: 'Calyces Regular',
		color: '#280b06',
		fontSize: 'calc(10px + 2vmin)',
		textAlign: 'left',
    lineHeight: '130%',
    '&::selection': {
      backgroundColor: '#FCE24C'
    }
	}
}));

export default function CenteredGrid(props) {
  const classes = useStyles()
	useEffect(() => {
    props.handleMenu('a')
  })

  return (
    <div className={classes.root}>
      <Fade 
      in={true}
      timeout={1000}
      >

      <Grid container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item md={10}>
        <Paper className={classes.paper} elevation={0}>
				<Typography className={classes.text}
				>
					{text}
				</Typography>
        </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0}>
            <div style={{color: '#fff'}}>
          <IconButton aria-label="search" color="inherit"
            href="mailto:contact@liminal.vision"
          >
            <Mail />
          </IconButton>
          <IconButton aria-label="display more actions" color="inherit"
            href="https://www.instagram.com/liminal.vision"
          >
            <Instagram />
          </IconButton>
          <IconButton aria-label="display more actions" color="inherit"
            href="https://www.linkedin.com/company/liminalvision"
          >
            <LinkedIn />
          </IconButton>
          </div>
          </Paper>
        </Grid>
      </Grid>
      </Fade>
    </div>
  );
}

const text = "liminal.vision explores value paradigms in emerging technocultural developments through speculative worldbuilding, material artifacts and immersive media. Grounded in the research field on ‘affect’ and its use through datafication, we unpack the design of affective undertones in different social systems by forward-engineering them from a critical perspective. Our goal is to contribute to public discourse on emerging technological conditions in the context of the 21st century's converging challenges."



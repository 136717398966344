import React from 'react';
import LayoutProject from '../components/v2/LayoutProject';

export default function Page(props) {
	React.useEffect(() => {
    props.handleMenu('p')
  })
	return (
		<LayoutProject
			data={data}
		/>
	)
}

const data = {
  title: 'interfaces',
	url: 'https://nvisible.studio/interfaces',
	img: 'interfaces.jpg',
  text: [
    "As part of our research, we conceptualize interfaces, build working prototypes and co-develop diegetic props manifesting within the logic of speculative fiction. Projects made by nvisible.studio during 2012-2016 share an interest in physiological computing and biofeedback, designed for adding new nonverbal modalities in interpersonal communication. Another running theme consists of calm interfaces and meditative bodily interactions rewiring attention and bodily relations with space and time. Liminal.vision’s worldbuilding approaches interfaces from the perspective of different value paradigms in combination with foresight and speculation about still-emerging technocultural evolutions such as voice interfaces, ambient intelligence and bodycryptography.",
  ]
}

import React, { useState, useEffect } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import Layout from './components/v2/Layout';

import Home from './pages/Home'
import About from './pages/About'
import Bios from './pages/Bios'
import Sonzai from './pages/Sonzai'
import Zhouwei from './pages/Zhouwei'
import Interfaces from './pages/Interfaces'

export default function App() {
  // Audio
  const [audio, setAudio] = useState(null)

  useEffect(() => {
    setAudio(new Audio('track.mp3'))
  // only run once on the first render on the client
  }, [])
  
  const [audioState, setAudioState] = useState(false);

  const start = () => {
    audio.play()
    setAudioState(true)
  }

  const pause = () => {
    audio.pause()
    setAudioState(false)
  }
  // Menu
  const [menu, setMenu] = useState('p')
  const handleMenu = (e) => {
    setMenu(e)
  }
  return (
    <Router>
      <Layout start={start} pause={pause} audioState={audioState} menu={menu}>
      <Switch>
      <Route exact path='/' render={(props) => (
        <Home {...props}  handleMenu={handleMenu}/>
      )} />
      <Route path='/about' render={(props) => (
        <About {...props}  handleMenu={handleMenu}/>
      )} />
       <Route path='/bios' render={(props) => (
        <Bios {...props}  handleMenu={handleMenu}/>
      )} />
      <Route path='/sonzai-zone' render={(props) => (
        <Sonzai {...props}  handleMenu={handleMenu}/>
      )} />
      <Route path='/zhouwei-network' render={(props) => (
        <Zhouwei {...props}  handleMenu={handleMenu}/>
      )} />      
      <Route path='/interfaces' render={(props) => (
        <Interfaces {...props}  handleMenu={handleMenu}/>
      )} />
      </Switch>
      </Layout>
    </Router>
  );
}

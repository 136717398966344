import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Project from './Project';
import Fade from '@material-ui/core/Fade';


const useStyles = makeStyles((theme) => ({
	img: {
		height: '400px',
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		'&::selection': {
      backgroundColor: '#FCE24C'
    }
  },
	imgGrid: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'block',
		}
	}
}));

export default function Sonzai(props) {
  const classes = useStyles()
  const {title, text, url, img} = props.data
	
	return (
    <Grid
			container spacing={6}
			direction="row"
			justify="center"
			alignItems="center"
		>
      <Grid item sm={12} md={6}>
				<Project 
					title={title}
					text={text}
					url={url}
				/>
      </Grid>
			<Fade 
			in={true}
			timeout={1000}
			>
      <Grid item md={6} className={classes.imgGrid}>
				<img alt='project' src={img} className={classes.img}/>
      </Grid>
			</Fade>

		</Grid>
	)
}


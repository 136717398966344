import React from 'react';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  title: {
		textDecoration: 'none',
    color: '#280b06',
		fontSize: 'calc(23px + 3vmin)',
		[theme.breakpoints.up('sm')]: {
			fontSize: 'calc(34px + 3vmin)',
		},	
		fontFamily: 'Calyces Regular',
		lineHeight: '130%',
		'&:hover': {
			color: '#FCE24C',
			textDecoration: 'none',
			fontFamily: 'Calyces Bold',
			textShadow: '3px 3px 13px #FCE24C',
			cursor: 'pointer',
		},
		'&::selection': {
      backgroundColor: '#FCE24C'
    }
  }
}));

export default function About(props) {
  const classes = useStyles();
	React.useEffect(() => {
    props.handleMenu('p')
  })
	return (
		<Fade 
		in={true}
		timeout={1000}
		>
    <Grid
			container
			direction="column"
			justify="center"
			alignItems="center"
		>
			{projects.map((obj) => (
					<Typography
						className={classes.title}
						key={obj.id}
						component={Link}
						to={obj.url}
					>
						{obj.title}
					</Typography>
			))}

		</Grid>
		</Fade>
	)
}

const projects = [
  {title:'sonzai.zone', url:'/sonzai-zone'},
  {title:'zhouwei.network', url:'/zhouwei-network'},
  {title:'interfaces', url:'/interfaces'}
]
import React from 'react';
import Grid from '@material-ui/core/Grid'
import Project from './Project';

export default function Sonzai(props) {
  const { nvisible, sxmbra } = props.data
	
	return (
    <Grid
			container spacing={6}
			direction="row"
			justify="center"
			alignItems="flex-start"
		>
      <Grid item md={6}>
				<Project 
					title={nvisible.title}
					text={nvisible.text}
					url={nvisible.url}
				/>
      </Grid>
      <Grid item md={6}>
				<Project 
					title={sxmbra.title}
					text={sxmbra.text}
					url={sxmbra.url}
				/>
      </Grid>

		</Grid>
	)
}

